.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-expansion-panels .v-expansion-panel--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: rgba(0, 0, 0, 0.54);
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:hover::before {
  opacity: 0.04;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:focus::before {
  opacity: 0.12;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:hover::before, .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active::before {
  opacity: 0.12;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover:hover::before {
  opacity: 0.04;
}
.theme--light.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover:focus::before {
  opacity: 0.12;
}
.theme--light.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover--active:hover::before, .theme--light.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover--active::before {
  opacity: 0.12;
}
.theme--light.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover--active:focus::before {
  opacity: 0.16;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #1E1E1E;
  color: #FFFFFF;
}
.theme--dark.v-expansion-panels .v-expansion-panel--disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #FFFFFF;
}
.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:hover::before {
  opacity: 0.08;
}
.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:focus::before {
  opacity: 0.24;
}
.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:hover::before, .theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active::before {
  opacity: 0.24;
}
.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover:hover::before {
  opacity: 0.08;
}
.theme--dark.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover:focus::before {
  opacity: 0.24;
}
.theme--dark.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover--active:hover::before, .theme--dark.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover--active::before {
  opacity: 0.24;
}
.theme--dark.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover--active:focus::before {
  opacity: 0.32;
}

.v-expansion-panels {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  z-index: 1;
}
.v-expansion-panels > * {
  cursor: auto;
}
.v-expansion-panels > *:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-expansion-panels > *:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border-radius: 4px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active + .v-expansion-panel {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active .v-expansion-panel-header {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-expansion-panel {
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-expansion-panel::before {
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-expansion-panel:not(:first-child)::after {
  border-top: thin solid;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s border-color cubic-bezier(0.4, 0, 0.2, 1), 0.2s opacity cubic-bezier(0.4, 0, 0.2, 1);
}
.v-expansion-panel--disabled .v-expansion-panel-header {
  pointer-events: none;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 16px;
}
.v-expansion-panel--active:not(:first-child)::after,
.v-expansion-panel--active + .v-expansion-panel::after {
  opacity: 0;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 64px;
}
.v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  transform: rotate(-180deg);
}

.v-expansion-panel-header__icon {
  display: inline-flex;
  margin-bottom: -4px;
  margin-top: -4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: auto;
}
.v-application--is-rtl .v-expansion-panel-header__icon {
  margin-right: auto;
}

.v-expansion-panel-header {
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 16px 24px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-application--is-ltr .v-expansion-panel-header {
  text-align: left;
}
.v-application--is-rtl .v-expansion-panel-header {
  text-align: right;
}
.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before {
  opacity: 0.12;
}
.v-expansion-panel-header:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: 1 1 auto;
}

.v-expansion-panel-content {
  display: flex;
}
.v-expansion-panel-content__wrap {
  padding: 0 24px 16px;
  flex: 1 1 auto;
  max-width: 100%;
}

.v-expansion-panels--accordion > .v-expansion-panel {
  margin-top: 0;
}
.v-expansion-panels--accordion > .v-expansion-panel::after {
  opacity: 1;
}

.v-expansion-panels--popout > .v-expansion-panel {
  max-width: calc(100% - 32px);
}
.v-expansion-panels--popout > .v-expansion-panel--active {
  max-width: calc(100% + 16px);
}

.v-expansion-panels--inset > .v-expansion-panel {
  max-width: 100%;
}
.v-expansion-panels--inset > .v-expansion-panel--active {
  max-width: calc(100% - 32px);
}

.v-expansion-panels--flat > .v-expansion-panel::after {
  border-top: none;
}
.v-expansion-panels--flat > .v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.v-expansion-panels--tile {
  border-radius: 0;
}
.v-expansion-panels--tile > .v-expansion-panel::before {
  border-radius: 0;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__bone::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}
.theme--light.v-skeleton-loader .v-skeleton-loader__avatar,
.theme--light.v-skeleton-loader .v-skeleton-loader__button,
.theme--light.v-skeleton-loader .v-skeleton-loader__chip,
.theme--light.v-skeleton-loader .v-skeleton-loader__divider,
.theme--light.v-skeleton-loader .v-skeleton-loader__heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__image,
.theme--light.v-skeleton-loader .v-skeleton-loader__text {
  background: rgba(0, 0, 0, 0.12);
}
.theme--light.v-skeleton-loader .v-skeleton-loader__actions,
.theme--light.v-skeleton-loader .v-skeleton-loader__article,
.theme--light.v-skeleton-loader .v-skeleton-loader__card-heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__card-text,
.theme--light.v-skeleton-loader .v-skeleton-loader__date-picker,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-text,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-two-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-three-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-thead,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-tbody,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-tfoot {
  background: #FFFFFF;
}

.theme--dark.v-skeleton-loader .v-skeleton-loader__bone::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
}
.theme--dark.v-skeleton-loader .v-skeleton-loader__avatar,
.theme--dark.v-skeleton-loader .v-skeleton-loader__button,
.theme--dark.v-skeleton-loader .v-skeleton-loader__chip,
.theme--dark.v-skeleton-loader .v-skeleton-loader__divider,
.theme--dark.v-skeleton-loader .v-skeleton-loader__heading,
.theme--dark.v-skeleton-loader .v-skeleton-loader__image,
.theme--dark.v-skeleton-loader .v-skeleton-loader__text {
  background: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-skeleton-loader .v-skeleton-loader__actions,
.theme--dark.v-skeleton-loader .v-skeleton-loader__article,
.theme--dark.v-skeleton-loader .v-skeleton-loader__card-heading,
.theme--dark.v-skeleton-loader .v-skeleton-loader__card-text,
.theme--dark.v-skeleton-loader .v-skeleton-loader__date-picker,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-text,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-two-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-three-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-heading,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-thead,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-tbody,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-tfoot {
  background: #1E1E1E;
}

.v-skeleton-loader {
  border-radius: 4px;
  position: relative;
  vertical-align: top;
}
.v-skeleton-loader__actions {
  padding: 16px 16px 8px;
  text-align: right;
}
.v-skeleton-loader__actions .v-skeleton-loader__button {
  display: inline-block;
}
.v-application--is-ltr .v-skeleton-loader__actions .v-skeleton-loader__button:first-child {
  margin-right: 12px;
}
.v-application--is-rtl .v-skeleton-loader__actions .v-skeleton-loader__button:first-child {
  margin-left: 12px;
}
.v-skeleton-loader .v-skeleton-loader__list-item,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar,
.v-skeleton-loader .v-skeleton-loader__list-item-text,
.v-skeleton-loader .v-skeleton-loader__list-item-two-line,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line,
.v-skeleton-loader .v-skeleton-loader__list-item-three-line,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line {
  border-radius: 4px;
}
.v-skeleton-loader .v-skeleton-loader__actions::after,
.v-skeleton-loader .v-skeleton-loader__article::after,
.v-skeleton-loader .v-skeleton-loader__card::after,
.v-skeleton-loader .v-skeleton-loader__card-avatar::after,
.v-skeleton-loader .v-skeleton-loader__card-heading::after,
.v-skeleton-loader .v-skeleton-loader__card-text::after,
.v-skeleton-loader .v-skeleton-loader__date-picker::after,
.v-skeleton-loader .v-skeleton-loader__date-picker-options::after,
.v-skeleton-loader .v-skeleton-loader__date-picker-days::after,
.v-skeleton-loader .v-skeleton-loader__list-item::after,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar::after,
.v-skeleton-loader .v-skeleton-loader__list-item-text::after,
.v-skeleton-loader .v-skeleton-loader__list-item-two-line::after,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line::after,
.v-skeleton-loader .v-skeleton-loader__list-item-three-line::after,
.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line::after,
.v-skeleton-loader .v-skeleton-loader__paragraph::after,
.v-skeleton-loader .v-skeleton-loader__sentences::after,
.v-skeleton-loader .v-skeleton-loader__table::after,
.v-skeleton-loader .v-skeleton-loader__table-cell::after,
.v-skeleton-loader .v-skeleton-loader__table-heading::after,
.v-skeleton-loader .v-skeleton-loader__table-thead::after,
.v-skeleton-loader .v-skeleton-loader__table-tbody::after,
.v-skeleton-loader .v-skeleton-loader__table-tfoot::after,
.v-skeleton-loader .v-skeleton-loader__table-row::after,
.v-skeleton-loader .v-skeleton-loader__table-row-divider::after {
  display: none;
}
.v-application--is-ltr .v-skeleton-loader__article .v-skeleton-loader__heading {
  margin: 16px 0 16px 16px;
}
.v-application--is-rtl .v-skeleton-loader__article .v-skeleton-loader__heading {
  margin: 16px 16px 0 16px;
}
.v-skeleton-loader__article .v-skeleton-loader__paragraph {
  padding: 16px;
}
.v-skeleton-loader__bone {
  border-radius: inherit;
  overflow: hidden;
  position: relative;
}
.v-skeleton-loader__bone::after {
  -webkit-animation: loading 1.5s infinite;
          animation: loading 1.5s infinite;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}
.v-skeleton-loader__avatar {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}
.v-skeleton-loader__button {
  border-radius: 4px;
  height: 36px;
  width: 64px;
}
.v-skeleton-loader__card .v-skeleton-loader__image {
  border-radius: 0;
}
.v-skeleton-loader__card-heading .v-skeleton-loader__heading {
  margin: 16px;
}
.v-skeleton-loader__card-text {
  padding: 16px;
}
.v-skeleton-loader__chip {
  border-radius: 16px;
  height: 32px;
  width: 96px;
}
.v-skeleton-loader__date-picker {
  border-radius: inherit;
}
.v-skeleton-loader__date-picker .v-skeleton-loader__list-item:first-child .v-skeleton-loader__text {
  max-width: 88px;
  width: 20%;
}
.v-skeleton-loader__date-picker .v-skeleton-loader__heading {
  max-width: 256px;
  width: 40%;
}
.v-skeleton-loader__date-picker-days {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  margin: 0 auto;
}
.v-skeleton-loader__date-picker-days .v-skeleton-loader__avatar {
  border-radius: 4px;
  flex: 1 1 auto;
  margin: 4px;
  height: 40px;
  width: 40px;
}
.v-skeleton-loader__date-picker-options {
  align-items: center;
  display: flex;
  padding: 16px;
}
.v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
}
.v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar:nth-child(2) {
  margin-left: auto;
}
.v-application--is-ltr .v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar:nth-child(2) {
  margin-right: 8px;
}
.v-application--is-rtl .v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar:nth-child(2) {
  margin-left: 8px;
}
.v-skeleton-loader__date-picker-options .v-skeleton-loader__text.v-skeleton-loader__bone:first-child {
  margin-bottom: 0px;
  max-width: 50%;
  width: 456px;
}
.v-skeleton-loader__divider {
  border-radius: 1px;
  height: 2px;
}
.v-skeleton-loader__heading {
  border-radius: 12px;
  height: 24px;
  width: 45%;
}
.v-skeleton-loader__image {
  height: 200px;
  border-radius: 0;
}
.v-skeleton-loader__image ~ .v-skeleton-loader__card-heading {
  border-radius: 0;
}
.v-skeleton-loader__image::first-child, .v-skeleton-loader__image::last-child {
  border-radius: inherit;
}
.v-skeleton-loader__list-item {
  height: 48px;
}
.v-skeleton-loader__list-item-three-line {
  flex-wrap: wrap;
}
.v-skeleton-loader__list-item-three-line > * {
  flex: 1 0 100%;
  width: 100%;
}
.v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar, .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__avatar, .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
}
.v-skeleton-loader__list-item-avatar {
  height: 48px;
}
.v-skeleton-loader__list-item-two-line, .v-skeleton-loader__list-item-avatar-two-line {
  height: 72px;
}
.v-skeleton-loader__list-item-three-line, .v-skeleton-loader__list-item-avatar-three-line {
  height: 88px;
}
.v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar {
  align-self: flex-start;
}
.v-skeleton-loader__list-item, .v-skeleton-loader__list-item-avatar, .v-skeleton-loader__list-item-two-line, .v-skeleton-loader__list-item-three-line, .v-skeleton-loader__list-item-avatar-two-line, .v-skeleton-loader__list-item-avatar-three-line {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}
.v-application--is-ltr .v-skeleton-loader__list-item .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-two-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-three-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar {
  margin-right: 16px;
}
.v-application--is-rtl .v-skeleton-loader__list-item .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-two-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-three-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar {
  margin-left: 16px;
}
.v-skeleton-loader__list-item .v-skeleton-loader__text:last-child,
.v-skeleton-loader__list-item .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-avatar .v-skeleton-loader__text:last-child,
.v-skeleton-loader__list-item-avatar .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-two-line .v-skeleton-loader__text:last-child,
.v-skeleton-loader__list-item-two-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-three-line .v-skeleton-loader__text:last-child,
.v-skeleton-loader__list-item-three-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__text:last-child,
.v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__text:last-child,
.v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__text:only-child {
  margin-bottom: 0;
}
.v-skeleton-loader__paragraph, .v-skeleton-loader__sentences {
  flex: 1 0 auto;
}
.v-skeleton-loader__paragraph:not(:last-child) {
  margin-bottom: 6px;
}
.v-skeleton-loader__paragraph .v-skeleton-loader__text:nth-child(1) {
  max-width: 100%;
}
.v-skeleton-loader__paragraph .v-skeleton-loader__text:nth-child(2) {
  max-width: 50%;
}
.v-skeleton-loader__paragraph .v-skeleton-loader__text:nth-child(3) {
  max-width: 70%;
}
.v-skeleton-loader__sentences .v-skeleton-loader__text:nth-child(2) {
  max-width: 70%;
}
.v-skeleton-loader__sentences:not(:last-child) {
  margin-bottom: 6px;
}
.v-skeleton-loader__table-heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.v-skeleton-loader__table-heading .v-skeleton-loader__heading {
  max-width: 15%;
}
.v-skeleton-loader__table-heading .v-skeleton-loader__text {
  max-width: 40%;
}
.v-skeleton-loader__table-thead {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.v-skeleton-loader__table-thead .v-skeleton-loader__heading {
  max-width: 5%;
}
.v-skeleton-loader__table-tbody {
  padding: 16px 16px 0;
}
.v-skeleton-loader__table-tfoot {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
.v-application--is-ltr .v-skeleton-loader__table-tfoot > * {
  margin-left: 8px;
}
.v-application--is-rtl .v-skeleton-loader__table-tfoot > * {
  margin-right: 8px;
}
.v-skeleton-loader__table-tfoot .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
}
.v-skeleton-loader__table-tfoot .v-skeleton-loader__text {
  margin-bottom: 0;
}
.v-skeleton-loader__table-tfoot .v-skeleton-loader__text:nth-child(1) {
  max-width: 128px;
}
.v-skeleton-loader__table-tfoot .v-skeleton-loader__text:nth-child(2) {
  max-width: 64px;
}
.v-skeleton-loader__table-row {
  display: flex;
  justify-content: space-between;
}
.v-skeleton-loader__table-cell {
  align-items: center;
  display: flex;
  height: 48px;
  width: 88px;
}
.v-skeleton-loader__table-cell .v-skeleton-loader__text {
  margin-bottom: 0;
}
.v-skeleton-loader__text {
  border-radius: 6px;
  flex: 1 0 auto;
  height: 12px;
  margin-bottom: 6px;
}
.v-skeleton-loader--boilerplate .v-skeleton-loader__bone:after {
  display: none;
}
.v-skeleton-loader--is-loading {
  overflow: hidden;
}
.v-skeleton-loader--tile {
  border-radius: 0;
}
.v-skeleton-loader--tile .v-skeleton-loader__bone {
  border-radius: 0;
}

@-webkit-keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
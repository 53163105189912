/* You can add global styles to this file, and also import other style files */
@import '~vuetify/dist/vuetify.css';

html, body {
	overflow: auto;
}

.topmost() {
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;

	align-items: center;
	justify-content: center;
	text-align: center;
}

.frozen-screen {

	@keyframes freeze {
		0% {
			background-color: rgba(255, 255, 255, 0.20);
		}
		100% {
			background-color: rgba(255, 255, 255, 0.80);
		}
	}

	.topmost();

	animation: freeze forwards 1s;
}

.loading {
	.topmost();
	background-color: white;
	font-weight: bold;
	font-family: 'Roboto', sans-serif;

	.info {
		color: rgb(1, 67, 97);
		background-color: rgb(229, 246, 253);
		border: 1px solid rgba(225, 225, 225, 0.15);
		padding: 30px 100px;
		border-radius: 4px;
	}

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;

		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 64px;
			height: 64px;
			margin: 8px;
			border-radius: 100%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-width: 5px;
			border-style: solid;
			border-color: rgb(23, 152, 218) transparent transparent transparent;
		}

		div:nth-child(1) {
			animation-delay: -0.45s;
		}

		div:nth-child(2) {
			animation-delay: -0.3s;
		}

		div:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

.error {
	display: block;
	width: 50vw;
	background-color: rgb(253, 237, 237);
	color: rgb(95, 33, 32);
	margin: 100px auto;
	padding: 40px;
	text-align: center;
	font-weight: bold;
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	border-radius: 4px;

	h1 {
		margin-bottom: 0.5em;
	}
}







